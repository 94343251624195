import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import YellowDots from "../../static/images/explore-yellow-dots.svg"
import PinkLine from "../../static/images/explore-pink-line.svg"
import YellowDot from "../../static/images/explore-yellow-dot.svg"
import BlueDots from "../../static/images/explore-blue-dots.svg"

const Subscribe = () => (
<Layout>
  <Helmet>
    <title>Request early access | IBDmate</title>
  </Helmet>
  <div className="layout-sign-up">
    <div className="purple-bg">
      <div className="content">
        <h1>Request early access</h1>
      </div>
      <div className="vectors-left">
        <img src={YellowDots} id="yellow-dots" alt="" />
        <img src={PinkLine} id="pink-line" alt="" />
      </div>
      <div className="vectors-right">
        <img src={YellowDot} id="yellow-dot" alt="" />
        <img src={BlueDots} id="blue-dots" alt="" />
      </div>
    </div>
    <div className="white-bg">
      <div className="wrapper">
        <form method="POST" netlify-honeypot="bot-field" data-netlify="true" name="subscribe" action="/subscribe-thank-you/">
          <input type="hidden" name="subject-field" />
          <input type="hidden" name="form-name" value="subscribe" />
          <p>We are just putting in place the finishing touches to IBDmate and it’ll be ready really soon.</p>
          <p>Want to be among the first to start using the platform? Request early access below and we’ll be sure to let you know when we are ready to go live.</p>
          <div className="form-field">
            <input
                type="text"
                placeholder="First name"
                name="f_name"
                className="input"
            />
          </div>
          <div className="form-field">
            <input
                type="text"
                placeholder="Surname"
                name="s_name"
                className="input"
            />
          </div>
          <div className="form-field">
            <input
                type="email"
                placeholder="Email"
                name="email"
                className="input"
            />
          </div>
          <button type="submit" className="btn btn-purple btn-lg">Sign up</button>
        </form>
      </div>
    </div>
  </div>
</Layout>
)

export default Subscribe
